import dynamic from 'next/dynamic';
import {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FaStar } from 'react-icons/fa';
import useSWR from 'swr';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Button, ButtonType } from '@components/Button';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { Fieldset } from '@components/PortableText/Form';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { CustomForm } from '@interfaces/IForms';
import { FORM_IDS } from '@lib/constants';
import { getFormById } from '@lib/queries/sanity/forms';
import { cdnClient } from '@lib/sanityClient';

const Modal = dynamic(() =>
  import('../Modal/Modal').then((mod) => mod.ModalNoPortal)
);

export function CustomerSentiment(): ReactElement {
  const [showModal, setShowModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>();
  const [submitting, setSubmitting] = useState(false);
  const [formId, setFormId] = useState<number>();
  const [submitted, setSubmitted] = useState(false);

  const {
    displayDarkMode: [darkModeIsOn],
  } = useContext<UIContextInterface>(UIContext);

  const { data: formData } = useSWR(
    FORM_IDS.CUSTOMER_SENTIMENT,
    async () =>
      await cdnClient.fetch<CustomForm>(getFormById, {
        id: FORM_IDS.CUSTOMER_SENTIMENT,
      })
  );

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
  });

  const updateSubmit: SubmitHandler<any> = async (data) => {
    setSubmitting(true);

    const response = await fetch('/api/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        formId: FORM_IDS.CUSTOMER_SENTIMENT,
        formData: {
          ...data,
          slug: window.location.pathname,
          stars: rating,
          original_id: formId,
        },
      }),
    });

    if (response.ok) {
      setShowModal(false);
    } else {
      const { error } = await response.json();
      setError(true);
      setErrorMessage(error.message.join('.'));
    }

    setSubmitting(false);
    setSubmitted(true);
    rhfMethods.reset();
  };

  const initialSubmit = async (star: number) => {
    setRating(star);

    const response = await fetch('/api/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        formId: FORM_IDS.CUSTOMER_SENTIMENT,
        formData: { slug: window.location.pathname, stars: star },
      }),
    });

    if (response.ok) {
      setShowModal(true);
      // set form id in db to use later in second post
      setFormId((await response.json()).data);
    } else {
      // reset stars and raise error
      const { error } = await response.json();
      setRating(0);
      setHover(0);
      setError(true);
      setErrorMessage(error.message.join('.'));
    }
  };

  // oddly works best with onMouseLeave on both the parent and individual star for
  // odd behaviours when cursor is moving really fast
  const StarRating = () => (
    <div className="flex items-center gap-2" onMouseLeave={() => setHover(0)}>
      {[1, 2, 3, 4, 5].map((star) => (
        <button
          key={star}
          disabled={showModal} // freeze stars when modal is open
          className="focus:outline-none"
          onClick={() => initialSubmit(star)}
          onMouseEnter={() => setHover(star)}
          onMouseLeave={() => setHover(0)}
        >
          <FaStar
            size={32}
            color={
              star <= (hover || rating)
                ? 'hsl(33, 80%, 52%)'
                : darkModeIsOn
                  ? 'hsl(0, 0%, 80%)'
                  : 'hsl(0, 0%, 40%)'
            }
          />
        </button>
      ))}
    </div>
  );

  useEffect(() => {
    if (!showModal) {
      setRating(0);
      setHover(0);
    }
  }, [showModal]);

  return !submitted ? (
    <>
      <div className="full-bleed my-12 flex flex-col items-center justify-center gap-2">
        <h3>How helpful was this page?</h3>
        <StarRating />
      </div>
      <div>
        <Modal
          show={showModal}
          setShow={setShowModal}
          className="flex items-center gap-6 dark:bg-black"
        >
          <h2 className="mt-4 italic">Thanks for the feedback</h2>
          <StarRating />
          {rating >= 3 ? (
            <div>...though we'd love a bit more detail.</div>
          ) : (
            <Paragraph align="center">
              If you had a problem with this page,{' '}
              <TextLink href="/contact-us">contact us</TextLink> and let us know
              why!
              <br />
              Our lovely customer service team is here to help!
            </Paragraph>
          )}
          {formData && (
            <FormProvider {...rhfMethods}>
              <form
                onSubmit={rhfMethods.handleSubmit(updateSubmit)}
                className="flex w-full flex-col gap-5 sm:w-3/5"
              >
                {formData.fieldsets.map((fieldset) => (
                  <Fieldset key={fieldset._key} fieldset={fieldset} />
                ))}
                <Button type={ButtonType.submit} disabled={submitting}>
                  Submit
                </Button>
              </form>
            </FormProvider>
          )}
        </Modal>
        <ErrorAlert show={error} setShow={setError}>
          <div>
            <Paragraph>
              Uh oh! Something went wrong trying to save that. Give it another
              try, and <TextLink href="/contact">get in touch</TextLink> if you
              continue to have issues.
            </Paragraph>
            <pre>{errorMessage}</pre>
          </div>
        </ErrorAlert>
      </div>
    </>
  ) : (
    <></>
  );
}
